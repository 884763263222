<template>
  <div>

    <v-snackbar :timeout="6000" elevation="26" v-model="snackbar" fixed bottom :color="colorSnack">
      <v-icon class="pa-2">mdi-information</v-icon> {{ snack }}
    </v-snackbar>

    <v-progress-linear v-model="progress" :buffer-value="bufferValue"></v-progress-linear>

    <!-- <v-overlay color="white darken-1" v-model="recognizing"></v-overlay> -->
    <v-overlay :color="colorSnack" dark opacity="1" :value="true">

      <v-container fill-height fluid>

        <v-row align="center" justify="center" v-show="vlay == 'nom'">

          <v-col cols="11" id="animations">

            <!-- <img  :z-index="12"  @click="$router.push('/')" width="230" height="150" 
              class="pa-4 ma-4"
              src="https://cdn.shopify.com/s/files/1/0356/7684/8173/files/cofidis_fond_rouge_x120@2x.png?v=1614769129" /> -->

            <v-text-field v-model="prenom" type="text" label="Votre prénom" clearable filled
              append-icon="mdi-shield-account"></v-text-field>

            <v-text-field v-model="nom" type="text" label="Votre nom" clearable filled
              append-icon="mdi-shield-account"></v-text-field>

            <v-menu filled ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" placeholder="Ex: 16 Mars 1988" ref="dob" filled label="Date de naissance"
                  append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1950-01-01" @change="save"></v-date-picker>
            </v-menu>

            <v-text-field filled ref="villeref" placeholder="Ex: Chartres" label="Ville de naissance"
              append-icon="mdi-map-marker-account-outline" v-model="ville"></v-text-field>

            <v-row v-if="ville.length > 2">
              <v-col cols="5">
                <v-text-field v-model="cp" label="Département de naissance" filled
                  append-icon="mdi-map-check"></v-text-field>
              </v-col>
              <v-col cols="7">
                <v-select filled label="Pays de naissance" v-model="pays"
                  :items="['France', 'Italie', 'Espagne']"></v-select>
              </v-col>
            </v-row>


            <v-row>
              <v-row justify="center" class="">
                <v-btn :disabled="!isGood" x-large class="mt-2" color="primary" @click="next">
                  Suivant<v-icon class="pa-2">mdi-skip-next</v-icon>
                </v-btn>
              </v-row>
            </v-row>

          </v-col>
        </v-row>

        <v-row class="pa-2" justify="center" v-show="vlay == 'naissance'">
          <v-col cols="11" id="animations">

            <v-text-field :disabled="true" v-model="email" type="email" label="Votre e-mail" clearable filled
              append-icon="mdi-email"></v-text-field>

            <v-text-field v-model="tel" type="tel" label="Quel est votre portable ?" clearable filled
              append-icon="mdi-phone"></v-text-field>
            <v-text-field v-model="adresse" @blur="adMaj" type="text" label="Quelle est votre adresse ?" clearable filled
              append-icon="mdi-map-marker-check"></v-text-field>
            <!-- <v-btn block @click="geoloc" small color="info" class="pa-4"><v-icon
              class="pa-2">mdi-crosshairs-gps</v-icon>Géolocalisez-moi !</v-btn> -->
            <v-select v-if="adresse.length > 2" filled label="Pays" v-model="pays"
              :items="['France', 'Italie', 'Espagne']"></v-select>
            <v-text-field v-if="adresse.length > 2" v-model="cp" label="Code Postal" filled
              append-icon="mdi-map-check"></v-text-field>
            <v-text-field filled v-if="adresse.length > 2" label="Ville" v-model="ville"></v-text-field>
            <v-row>

            </v-row>
            <v-row>

              <!-- <v-row justify="center" class="ma-2">
  <v-btn  x-large color="primary" @click="finish">
    <v-icon>mdi-check</v-icon>Je finalise mon achat
  </v-btn>
</v-row> -->
            </v-row>


          </v-col>
        </v-row>
      </v-container>


      <v-container style="margin-top: -450px;" v-show="vlay == 'finish'" class="fadein-slideup"
        :class="{ active: showDiv }">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="6">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <v-alert type="success">
              Un SMS vous a été envoyé. Cliquez sur le lien afin de finaliser votre demande et obtenir votre réponse
              immédiate.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>

    </v-overlay>

    <v-row>
      <v-row justify="center" class="ma-2 mt-10">
        <v-btn x-large class="mt-8" color="primary" @click="finish">
          Suivant<v-icon class="pa-2">mdi-send</v-icon>
        </v-btn>
      </v-row>
    </v-row>




    <v-row align="center" justify="center">

      <audio ref="audioPlayer">
        <source :src="audioSrc" type="audio/mpeg">
      </audio>
      <canvas ref="canvas" style="position: absolute; top: 0; left: 0;"></canvas>

      <v-btn :loading="loading3" v-if="!recognizing && !captureShow" class="appearing" color="primary" elevation="10"
        style="z-index: 9999999999" fab big x-large fixed bottom dark @click="speech">
        <v-icon>mdi-microphone</v-icon>
      </v-btn>

      <v-btn v-if="finished" class="appearing" color="green darken-1" elevation="10" style="z-index: 9999999999" fab big
        x-large fixed bottom dark>
        <v-icon>mdi-check-circle</v-icon>
      </v-btn>

      <v-btn v-else-if="recognizing && !captureShow" color="red" class="pulsing" elevation="10"
        style="z-index: 9999999999" fab big x-large fixed bottom dark @click="stop">
        <v-icon>mdi-record</v-icon>
      </v-btn>

    </v-row>

  </div>
</template>

<style>
#map {
  height: 180px;
}


.fadein {
  opacity: 1;
  animation: fade 1s linear;
}

.fadein-slideup {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.fadein-slideup.active {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
<script>
import xhr from "../plugins/axios";
import moment from "moment";
import 'moment/locale/fr';


import Destination from "./Destination";

// moment.locale("fr");

import axios from 'axios';
import './Home.scss'
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: "home",
  components: {
    Destination,
  },
  data(vm) {
    return {
      showDiv: false,
      loading3: false,
      email: '',
      audioSrc: '',
      colorSnack: 'primary',
      vlay: 'nom',
      user: {},
      show: true,
      speechVoice: null,
      captureShow: false,
      captured: "",
      map: null,
      progress: 0,
      bufferValue: 0,
      src: "",
      login: "",
      password: "",
      type: "",
      description: "",
      recognizing: false,
      recognition: {},
      ville: "",
      tel: '',
      final_transcript: "",
      icon: null,
      attribution: 'Licensed by &copy; <a href="some_link", class="your_class">Habijabi</a>',
      zoom: 15,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      center: { lat: 45.764043, lng: 4.835659 },
      markerLatLng: { lat: 45.764043, lng: 4.835659 },
      nom: '',
      cgu: false,
      prenom: '',
      cp: "",
      departement: "",
      pays: "France",
      activePicker: null,
      date: null,
      menu: false,
      dialogPreview: false,
      playing: null,
      playingpause: false,
      idplay: false,
      id: null,
      destinationSelected: null,
      dialogConf: false,
      destination: {},
      dialogNew: false,
      valid: false,
      countries: [],
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      finished: false,
      play: null,
      istwo: false,
      isthree: false,
      video: null,
      isReady: false,
      interval: null,
      timeout: null,
      action: "new",
      destinations: [],
      search: "",
      adresse: "",
      dialogManager: false,
      snackbar: false,
      dialog: false,
      snack: "Votre compte a bien été activé",
      confirm: false,
      users: [],
      accessToken:
        "pk.eyJ1IjoibWFudWVsdGFpd2EiLCJhIjoiY2w0c2xzZml1MDUzejNpbzYwd294NDZycCJ9.ELDGOASK0iPzLZmDkWzAlg", // your access token. Needed if you using Mapbox maps
      styles: {
        type: Object,
        default: () => { },
      },
      plugins: {
        type: Object,
        default: () => { },
      },
    };
  },
  async created() {

    setTimeout(() => {
      this.snack = "Parlez en appuyant sur ce micro"
      this.snackbar = true
    }, 2000)

  },
  async mounted() {
    await this.loadSpeech()
  },
  methods: {
    next() {
      if (this.istwo == false) {
        if (this.ville.length && this.date.length && this.nom.length && this.prenom.length && this.istwo == false) {
          this.loading3 = true
          this.stop()
          this.vlay = "naissance"
          this.audioSrc = require('@/assets/son/voice2.mp3')
          const audioPlayer = this.$refs.audioPlayer;
          audioPlayer.load();
          audioPlayer.play();
          this.istwo = true
          setTimeout(() => {
            this.speechTwo()
          }, 5000)
        }
      }
    },
    speeching(txt) {
      this.speechVoice.text = txt;
      window.speechSynthesis.speak(this.speechVoice);
    },


    checkPermission(permissionName) {
      try {
        navigator.permissions.query(Object.assign({ name: permissionName }))
          .then((permissionStatus) => {
            // alert(permissionStatus)
            //  alert(permissionStatus.state)
            //  alert(permissionStatus.state)
            if (permissionName == "geolocation") {
              navigator.geolocation.getCurrentPosition(this.showPosition, (message) => {
                alert("Accès non authorisé en localisation. Vérifier vos paramètres d'authorisation sur votre Smartphone.")
                // alert(message)
              },
                {
                  enableHighAccuracy: true,
                  timeout: 20000,
                  maximumAge: 1000
                }
              );
            }

          });
      } catch (e) {
        alert(e)
      }
    },
    async doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject
    },


    async loadSpeech() {

      // Vérifier si l'API MediaDevices est disponible dans le navigateur
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Demander la permission pour le microphone
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
          .then((stream) => {
            // L'utilisateur a donné la permission d'accéder au microphone
            // Vous pouvez utiliser le flux audio ici
            this.recognition = new webkitSpeechRecognition() || new SpeechRecognition();
            console.log("this.recognition", this.recognition);
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.onstart = () => {
              this.final_transcript = ''
              if (this.nom.length == "" && this.prenom.length == "") {
                this.snack = "Donnez votre nom, prénom date et lieux de naissance";
                this.snackbar = true;
              }
              this.recognizing = true;
            };


            this.recognition.onerror = (event) => {
              this.recognizing = false;
            };

            this.recognition.onsoundend = () => {
              this.recognizing = false;
              this.recognition.stop()
            };

            this.recognition.onresult = async (event) => {

              let interim_transcript = '';
              for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                  this.final_transcript += event.results[i][0].transcript;
                } else {
                  interim_transcript += event.results[i][0].transcript;
                }
              }

              this.final_transcript = this.capitalize(this.final_transcript);
              if (this.final_transcript) {

                console.log("this.final_transcript", this.final_transcript);


                let adresse = this.final_transcript.match(/(J'habite|je suis|adresse|localisation|le quai|le pont|devant|derrière|je suis|au niveau|je me situe|aux? boulevard|aux? carrefour|dans la rue|dans l'avenue|dans le cours)/igm)
                let tel = this.final_transcript.match(/(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/igm)
                let telNew = this.final_transcript.match(/(téléphone|portable|Mon numéro de portable est|Mon numéro est le|Mon portable est le)/igm)
                let ville = this.final_transcript.match(/(La ville est|La ville de|La ville et|ville est|ville et|ville de)/igm)
                let prenom = this.final_transcript.match(/(Myriam|Arnaud|Maxime|Kévin|Kevin|Gilles|Mathilde|Nathalie|Thibault|Thibaud|Maxime|julien|romain|arnaud|arnault|manuel|simon|frédéric|césar|cesar|raphaël|arthur|louis|jules|hugo|lucas|paul|pierre|victor)/igm)
                let nom = this.final_transcript.match(/(Akeniouine|Gall|Legall|arnault|dupont|dupond|durant|boyer|martin|bernard|dubois|thomas|robert|richard|petit)/igm)
                let birth = this.final_transcript.match(/(née? le|Ma date de naissance est le|date de naissance|naissance|anniversaire)/igm)
                let cityBirth = this.final_transcript.match(/(Je suis née? à|née? à|à|ville|ville de naissance|née dans|suis de|originaire|origine)/igm)
                let depBirth = this.final_transcript.match(/(département est|département et|code postal|zipcode|région)/igm)



                if (adresse) {
                  adresse = this.final_transcript
                  let reg = /[0-9]{0,3},? (rue|ruelle|boulevard|cours|montée|allé|allée|résidence|passage|avenue|quai|pont|passerelle|chemin|place|impasse)[[a-zA-ZÀ-ÿ0-9-.\' ]{2,}/gmi

                  let detect = (str) => {
                    let ok = str.match(reg)
                    if (ok && ok.length) {
                      let res = ok[0]
                      let oreg = / (et|ou|puis|voilà|mon) /
                      res = res.split(oreg);
                      if (res.length) {
                        return res[0]
                      }
                      return false;
                    }

                  }

                  let res = detect(adresse)
                  if (res) {
                    this.adresse = res.trim()

                    console.log(" this.adresse", this.adresse);

                    const regex = /avenue\s+allée|avenue Aley|Av. Aley/gi; // Recherche 'avenue' suivi d'un ou plusieurs espaces puis 'allée'
                    const replacement = "avenue Halley 59866 VILLENEUVE D’ASCQ";

                    if (regex.test(this.adresse)) {
                      this.adresse = this.adresse.replace(regex, replacement);
                      this.cp = "59866";
                      this.ville = "VILLENEUVE D’ASCQ";
                    }


                    let ad = encodeURI(this.adresse)
                    console.log("ad", ad);


                    let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${ad}&sensor=true&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
                    if (dat.data.results && dat.data.results.length) {
                      if (dat.data.results[0]) {
                        let result = dat.data.results[0]

                        for (const component of result.address_components) {

                          console.log("component", component);
                          if (component.types.includes('locality')) {
                            this.ville = component.long_name; // Renvoie le nom de la ville
                          }

                          if (component.types.includes('postal_code')) {
                            this.cp = component.long_name;
                          }

                          if (component.types.includes('postal_code_prefix')) {
                            this.cp = component.long_name;
                          }
                          if (component.types.includes('postal_code_suffix')) {
                            this.cp = component.long_name;
                          }
                        }



                      }
                    }
                  }
                }


                if (cityBirth) {
                  console.log("cityBirth", cityBirth);
                  let city = this.final_transcript.split(cityBirth)
                  if (city.length > 1) {

                    let recity = city[1].trim().replace(" ", "").replace(/(denaissance|denaissance et|et|est)/igm, "")
                    console.log("city", recity);
                    //  this.ville = recity
                    let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(recity)}&sensor=false&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
                    const { results } = dat.data;
                    if (results.length > 0) {
                      const result = results[0];
                      for (const component of result.address_components) {
                        if (component.types.includes('locality')) {
                          this.ville = component.long_name; // Renvoie le nom de la ville
                        }

                        if (component.types.includes('postal_code')) {
                          this.cp = component.long_name; // Renvoie le code postal
                          console.log(" this.cp ", this.cp);
                        }
                      }
                    }
                    console.log("recity", recity);
                    if (recity === "Paris") {
                      this.cp = 75000
                    } else if (recity === "Lyon") {
                      this.cp = 69000
                    }
                    else if (recity === "Marseille") {
                      this.cp = 13000
                    }
                    else if (recity === "Bordeaux") {
                      this.cp = 33300
                    }
                  }




                }



                if (ville && ville.length >= 1) {
                  let match = this.final_transcript.split(ville)
                  console.log(" match", match);

                  // this.ville = match[1]

                  let ad = encodeURI(this.ville)
                  console.log("ad", ad);

                  let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${ad}&sensor=true&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
                  if (dat.data.results && dat.data.results.length) {
                    if (dat.data.results[0]) {
                      let result = dat.data.results[0]

                      for (const component of result.address_components) {

                        console.log("component", component);

                        if (component.types.includes('locality')) {
                          this.ville = component.long_name; // Renvoie le nom de la ville
                        }

                        if (component.types.includes('postal_code')) {
                          this.cp = component.long_name;
                        }

                        if (component.types.includes('postal_code_prefix')) {
                          this.cp = component.long_name;
                        }
                        if (component.types.includes('postal_code_suffix')) {
                          this.cp = component.long_name;
                        }
                      }
                    }
                  }

                  // setTimeout(() =>{
                  //   this.vlay = 'naissance'
                  // }, 2000 )

                }

                if (depBirth) {
                  console.log("depBirth", depBirth);
                  let dep = this.final_transcript.split(depBirth)
                  if (dep.length > 1) {

                    let redep = dep[1].trim().replace(/(est|et|le|la|l\')/igm, "")
                    console.log("dep", redep);
                    // this.cp = redep
                    let dat = await axios.get(`https://geo.api.gouv.fr/departements?nom=${encodeURIComponent(redep)}&limit=1`)
                    const results = dat.data;

                    console.log("results");
                    if (results.length > 0) {
                      const result = results[0];
                      console.log("result", result);
                      this.cp = result.code + '000'
                    }
                  }
                }

                if (birth && birth.length) {

                  //this.date = '2022-03-16';
                  let reg = /[0-9]{1,2} (janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre) [0-9]{2,4}/igm

                  let match = this.final_transcript.match(reg)
                  // console.log("match", match);
                  if (match && match.length) {
                    moment.locale('fr');
                    console.log("match[0]", match[0]);
                    let mom = moment(match[0], "DD MMMM YYYY")
                    if (mom.isValid()) {
                      this.date = mom.format("DD/MM/YYYY")
                      // this.$refs.villeref.focus()
                    }
                  }


                }

                if (prenom && prenom.length) {
                  this.prenom = prenom[0].charAt(0).toUpperCase() + prenom[0].slice(1);
                }

                if ((nom && nom.length) || (prenom && prenom.length)) {
                  let renom = ''
                  if (this.prenom == "Arnaud") {
                    renom = 'Legall'
                    this.email = "arnaud.legall@cofidis.fr"

                  } else if (this.prenom == "Myriam") {
                    renom = 'Akeniouine'
                    this.email = "myriam.akeniouine@cofidis.fr"
                  }
                  else if (this.prenom == "Kevin" || this.prenom == "Kévin") {
                    renom = 'Grassa'
                    this.email = "kevin.grassa@cofidis.fr"
                  }
                  else if (this.prenom == "Gilles") {
                    renom = 'Menez'
                    this.email = "gilles.menez@cofidis.fr"
                  }
                  else if (this.prenom == "Mathilde") {
                    renom = 'Viatour'
                    this.email = "mathilde.viatour@cofidis.fr"
                  }
                  else if (this.prenom == "Nathalie") {
                    renom = 'Pison'
                    this.email = "natpison@amazon.fr"
                  }
                  else if (this.prenom == "Thibault" || this.prenom == "Thibaud") {
                    renom = 'Meunier'
                    this.email = "meuniet@amazon.fr"
                  }
                  else if (this.prenom == "Maxime") {
                    renom = 'Fernet'
                    this.email = "fernet@amazon.fr"
                  }
                  this.nom = renom;

                  // this.$refs.dob.focus()
                }

                if ((tel || telNew) && !this.tel.length) {
                  if (tel && tel.length) {
                    this.tel = tel[0]
                  }
                }

                if (this.ville.length && this.date.length && this.nom.length && this.prenom.length && this.istwo == false) {
                  setTimeout(() => {
                    if (this.istwo == false) {
                      setTimeout(() => {
                        this.loading3 = true
                        this.stop()
                        this.vlay = "naissance"
                        this.audioSrc = require('@/assets/son/voice2.mp3')
                        const audioPlayer = this.$refs.audioPlayer;
                        audioPlayer.load();
                        audioPlayer.play();
                        this.istwo = true
                      }, 5000)

                      setTimeout(() => {
                        this.speechTwo()
                      }, 5000)
                    }
                  }, 5000)
                }


                if (this.tel.length && this.adresse.length && this.isthree == false) {
                  // this.snack = "Merci pour ces informations !";
                  // this.snackbar = true;
                  setTimeout(async () => {
                    this.audioSrc = require('@/assets/son/voice3.mp3')
                    const audioPlayer = this.$refs.audioPlayer;
                    this.finished = true
                    audioPlayer.load();
                    audioPlayer.play();
                    console.log("terminé!!");
                    let res = await xhr.post('/sms', {
                      sms: this.tel
                    })
                    this.vlay = "finish"
                    this.isthree = true
                    this.stop()
                    this.showDiv = true
                    this.colorSnack = 'white'

                  }, 5000)
                }


                // if (this.nom.length && this.prenom.length && this.date.length && this.cp.length && this.ville.length) {
                //   this.snack = "Merci pour ces informations !"
                //   this.snackbar = true
                //   this.colorSnack = 'info'
                //   // setTimeout(() =>{
                //   //   this.$router.push('/next')
                //   // },2000)
                // }

              }
            };

            this.recognition.onend = (event) => {
              this.recognizing = false
              this.recognition.stop()
            }
          })
          .catch(function (error) {
            // Une erreur s'est produite ou l'utilisateur a refusé l'accès au microphone
            console.error('Erreur lors de la demande de permission pour le microphone:', error);
          });
      } else {
        console.error('L\'API MediaDevices.getUserMedia n\'est pas prise en charge dans ce navigateur.');
      }
    },
    previsu(event) {
      const [file] = imgInp.files
      if (file) {
        this.src = URL.createObjectURL(file)
      }
    },
    async geoloc() {
      await this.getLocation()
    },
    async upLong(val) {
      let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${val.lat},${val.lng}&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
      if (dat.data.results && dat.data.status == 'OK' && dat.data.results.length) {
        this.adresse = dat.data.results[0].formatted_address
        // this.cp = dat.data.results[0].formatted_address.match(/[0-9]{5}/)[0]
      }

    },
    async adMaj() {
      let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.adresse}&sensor=false&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
      if (dat.data.results && dat.data.results.length) {
        if (dat.data.results[0]) {
          let one = dat.data.results[0]
          this.center = one.geometry.location
          this.markerLatLng = one.geometry.location
          this.adresse = one.formatted_address
          this.zoom = 17
        }
      }
    },

    getMobileOperatingSystem() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
    async finish() {

      this.$router.push('/next')

      // let res = await xhr.post(`/PortailJSON?flowType=EAII&flowName=SRV_Mobile_Data&actionJSON=launch&enforceAsString=false`, datas)
      // if (res.data && res.data["MNTDeclarationMobileApp.oResult"]) {
      //   this.$router.push('/success')
      // }
    },
    stop() {
      this.isReady = true
      this.progress = 0
      this.bufferValue = 0
      clearInterval(this.interval)
      clearTimeout(this.timeout)
      this.recognition.stop()
      this.recognition.onend = (e) => {
        this.recognizing = false
      }
      this.final_transcript = ""
    },
    capitalize(s) {
      var first_char = /\S/;
      return s.replace(first_char, function (m) { return m.toUpperCase(); });
    },
    linebreak(s) {
      var two_line = /\n\n/g;
      var one_line = /\n/g;
      return s.replace(two_line, '<p></p>').replace(one_line, '<br>');
    },
    async speech() {

      const speeching = () => {
        this.loading3 = false
        if (!this.recognition) return false
        this.recognition.start()
        this.progress = 0
        this.bufferValue = 0
        clearInterval(this.interval)
        clearTimeout(this.timeout)

        this.interval = setInterval(() => {
          this.progress += 5
          this.bufferValue += 5
        }, 1000)

        this.recognition.onend = (e) => {
          this.recognizing = false
          clearInterval(this.interval)
          clearTimeout(this.timeout)
          this.progress = 0
          this.bufferValue = 0
          this.final_transcript = ""
        }

        this.timeout = setTimeout(() => {
          this.recognition.stop()
          clearInterval(this.interval)
          clearTimeout(this.timeout)
          this.progress = 0
          this.bufferValue = 0
          this.final_transcript = ""
          // this.snackbar = true
          // this.snack = "Fin !"
        }, 30000)

      }

      if (!this.nom.length && !this.prenom.length) {

        this.loading3 = true
        setTimeout(() => {
          speeching()
        }, 8000)

        this.audioSrc = require('@/assets/son/voice1.mp3')
        const audioPlayer = this.$refs.audioPlayer;
        audioPlayer.load();
        audioPlayer.play();
      } else {
        this.snackbar = true
        this.snack = "Veillez compléter toutes les informations"
        speeching()
      }


    },
    async speechTwo() {
      this.loading3 = false
      this.recognition.start()
      this.progress = 0
      this.bufferValue = 0
      clearInterval(this.interval)
      clearTimeout(this.timeout)

      this.interval = setInterval(() => {
        this.progress += 5
        this.bufferValue += 5
      }, 1000)

      this.recognition.onend = (e) => {
        this.recognizing = false
        clearInterval(this.interval)
        clearTimeout(this.timeout)
        this.progress = 0
        this.bufferValue = 0
        this.final_transcript = ""
      }

      this.timeout = setTimeout(() => {
        this.recognition.stop()
        clearInterval(this.interval)
        clearTimeout(this.timeout)
        this.progress = 0
        this.bufferValue = 0
        this.final_transcript = ""
        // this.snackbar = true
        // this.snack = "Fin !"
        this.stop()
      }, 30000)

    },
    getLocation() {
      if (navigator.permissions && navigator.permissions.query) {

        //try permissions APIs first
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {

          // Will return ['granted', 'prompt', 'denied']
          const permission = result.state;
          // alert(permission)
          //  alert(permission) // prompt
          if (permission === 'granted' || permission === 'prompt') {
            navigator.geolocation.getCurrentPosition(this.showPosition, (message) => {
              alert("Accès non autorisé en localisation. Vérifier vos paramètres d'authorisation sur votre Smartphone.")
            },
              {
                enableHighAccuracy: false,
                timeout: 20000,
                maximumAge: 1000
              }
            );
          }
        });
      }
      else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },

    async showPosition(position) {
      // alert("Show position go");
      // alert(position)
      this.markerLatLng = { lat: position.coords.latitude, lng: position.coords.longitude }
      // alert("gooooiiiinnnng !! ")
      // alert(position)

      // alert(position.coords, "position.coords")
      // alert(position.coords.latitude, "position.latitude")
      // alert(position.coords.longitude, "position.longitude")

      let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
      //  alert(dat.data.status, "dat.data.status")

      if (dat.data.results && dat.data.status == 'OK' && dat.data.results.length) {
        this.adresse = dat.data.results[0].formatted_address
        this.cp = dat.data.results[0].formatted_address.match(/[0-9]{5}/)[0]
        this.zoom = 16

        // this.center =  this.markerLatLng
      }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    selected(id) {
      this.id = id;
    },
    playe(url) {
      this.playing = new Audio(url);
      this.playing.play();
      this.playingpause = true;
    },
    pauses() {
      this.playing.pause();
      this.playingpause = false;
    },
    async edit(destination) {
      this.destination = destination;
      this.dialogNew = true;
    },
    async remove(id) {
      let destinations = await xhr.post("/destinations/remove", { id });
      this.destinations = destinations.data;
    },
    async refresh(destinations) {
      this.destinations = destinations;
    },
    async rand() {
      this.user.token = this.random();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day}/${month}/${year}`;
    },
    random() {
      return Math.random().toString(36).slice(2);
    },
    async filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    async loading() {
      let tab = await xhr.get("/destinations");
      this.destinations = Object.values(tab.data);
    },
    async enable(uid) {
      this.snack = "Ce compte a bien été activé";
      await xhr.post("/user/enable", { uid });
      this.snackbar = true;
      await this.loading();
    },
    async disable(uid) {
      this.snack = "Ce compte a bien été desactivé";
      await xhr.post("/user/disable", { uid });
      this.snackbar = true;
      await this.loading();
    },
    formatDate(datIn) {
      return moment(datIn).format("DD/MM/YYYY");
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {

    isGood() {
      return (this.ville && this.ville.length && this.date && this.date.length && this.nom.length && this.prenom.length && this.istwo == false)
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    destinationsFilter() {
      if (this.search.length > 2) {
        let reg = new RegExp(this.search, "ig");
        return this.destinations.filter(
          (elt) =>
            reg.test(elt.titre) ||
            reg.test(elt.mois.map((e) => e.name).join(" ")) ||
            reg.test(elt.short) ||
            reg.test(elt.description) ||
            reg.test(elt.legende) ||
            reg.test(elt.nature) ||
            reg.test(elt.country) ||
            reg.test(elt.type)
        );
      }
      return this.destinations;
    },
    popupCoordinates() {
      // Here we can do some work for calculate proper coordinates
      //...
      return [4.8342778, 45.763421];
    },
  },
};
</script>
