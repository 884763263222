<template>
  <div justify="center" class="">

    <v-snackbar :timeout="4000"    elevation="24" v-model="snackbar"
        fixed
        bottom
        color="primary"
    >
      {{ snack }}
    </v-snackbar>

    <div>
      <!-- <v-overlay color="white darken-1" v-model="recognizing"></v-overlay> -->
        

    </div>
  </div>
</template>

<style>
#map {
  height: 180px;
}


.fadein {
  opacity: 1;
  animation: fade 1s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
<script>
import xhr from "../plugins/axios";
import moment from "moment";
import 'moment/locale/fr';


import Destination from "./Destination";
// moment.locale("fr");

import axios from 'axios';
import './Home.scss'
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: "home",
  components: {
    Destination,
  },
  data(vm) {
    return {
      stepTwo: false,
      audioSrc: '',
      show: true,
      speechVoice: null,
      captureShow: false,
      captured: "",
      map: null,
      progress: 0,
      bufferValue: 0,
      src: "",
      login: "",
      password: "",
      type: "",
      description: "",
      recognizing: false,
      recognition: {},
      ville: "",
      tel: '',
      final_transcript: "",
      icon: null,
      attribution: 'Licensed by &copy; <a href="some_link", class="your_class">Habijabi</a>',
      zoom: 15,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      center: { lat: 45.764043, lng: 4.835659 },
      markerLatLng: { lat: 45.764043, lng: 4.835659 },
      nom: '',
      cgu: false,
      prenom: '',
      cp: "",
      departement: "",
      pays: "France",
      activePicker: null,
      date: null,
      menu: false,
      dialogPreview: false,
      playing: null,
      playingpause: false,
      idplay: false,
      id: null,
      destinationSelected: null,
      dialogConf: false,
      destination: {},
      dialogNew: false,
      valid: false,
      countries: [],
      
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
   
      rules: {
        required: (value) => !!value || "Obligatoire.",
        min: (value) => (value && value.length > 3) || "Pas assez long",
        password: (value) => (value && value.length > 7) || "Pas assez long",
        email: (value) => email.test(value) || "E-mail non valide",
        long: (value) => long.test(value) || "Non valide",
        repass: (value) =>
          (value && value.length > 7 && this.manager.password == value) ||
          "Confirmation non valide",
      },
      play: null,
      video: null,
      isReady: false,
      interval: null,
      timeout: null,
      action: "new",
      destinations: [],
      search: "",
      adresse: "",
      dialogManager: false,
      snackbar: false,
      dialog: false,
      snack: "Votre compte a bien été activé",
      confirm: false,
      users: [],
      accessToken:
        "pk.eyJ1IjoibWFudWVsdGFpd2EiLCJhIjoiY2w0c2xzZml1MDUzejNpbzYwd294NDZycCJ9.ELDGOASK0iPzLZmDkWzAlg", // your access token. Needed if you using Mapbox maps
      styles: {
        type: Object,
        default: () => { },
      },
      plugins: {
        type: Object,
        default: () => { },
      },
    };
  },
  async created() {

    setTimeout(() =>{
      this.snack = "Vous pouvez commencer en appuyant sur ce micro"
      this.snackbar = true
    }, 2000)

    // this.speechVoice = new SpeechSynthesisUtterance();
    // this.speechVoice.lang = "fr-FR";
    // this.speeching("Bonjour")


    // if (!('webkitSpeechRecognition' in window)) {
    //   alert("No recognize speech browser")
    // }

    // this.getLocation()
    // this.checkPermission('geolocation');

  },
  async mounted() {
    this.audioSrc = require('@/assets/son/voice2.mp3')
    const audioPlayer = this.$refs.audioPlayer;
    audioPlayer.load();
    audioPlayer.play();

    setTimeout(() =>{
        this.speech()
    }, 8000)

    // setTimeout(() => {
    //   let audio  = new Audio('../assets/son/debut.m4a')
    //   // audio.play()
    // }, 1000)

    // let video = null;
    // let photo = null;
    // photo = this.$refs.photo;
    // video = this.$refs.video;

    // navigator.mediaDevices
    //   .getUserMedia({ video: true, audio: false })
    //   .then((stream) => {
    //     video.srcObject = stream;
    //     video.play();
    //   })
    //   .catch((err) => {
    //     console.error(`Une erreur est survenue : ${err}`);
    //   });

    // let conf = confirm("Autorisez-vous la géolocation pour Cofidis ?")
    // if (conf) {
      // this.checkPermission('geolocation');
    // }
    await this.loadSpeech()

    //  this.getLocation()
    // this.$nextTick(() => {
    //   this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD();
    // });


  },
  methods: {

    speeching(txt){
      
      this.speechVoice.text = txt;
      window.speechSynthesis.speak(this.speechVoice);
    },

    takepicture() {
      let canvas = document.getElementById('canvas');
      let video = this.$refs.video;
      // console.log("canvas", canvas);

      const context = canvas.getContext("2d");
      const width = 320;    // On redimensionnera la photo pour avoir cette largeur
      const height = 200;

      if (width && height) {

        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);

        const data = canvas.toDataURL("image/png");
        this.src = data
        this.captureShow = false

      } else {
        this.clearphoto();
      }

    },

    clearphoto() {
      const context = canvas.getContext("2d");
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const data = canvas.toDataURL("image/png");
      this.src = data
      this.captureShow = false
    },

    checkPermission(permissionName) {
      try {
        navigator.permissions.query(Object.assign({ name: permissionName }))
          .then((permissionStatus) => {
            // alert(permissionStatus)
            //  alert(permissionStatus.state)
            //  alert(permissionStatus.state)
            if (permissionName == "geolocation") {
              navigator.geolocation.getCurrentPosition(this.showPosition, (message) => {
                alert("Accès non authorisé en localisation. Vérifier vos paramètres d'authorisation sur votre Smartphone.")
                // alert(message)
              },
                {
                  enableHighAccuracy: true,
                  timeout: 20000,
                  maximumAge: 1000
                }
              );
            }

          });
      } catch (e) {
        alert(e)
      }
    },
    async doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject
    },

    async loadSpeech() {
      this.recognition = new webkitSpeechRecognition() || new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onstart = () => {
        this.final_transcript = ''
        this.snack = "Vous pouvez commencez à parler";
        this.snackbar = true;
        this.recognizing = true;
      };

      this.recognition.onaudiostart = () => {
        this.recognizing = true;
      };

      this.recognition.onerror = (event) => {
        this.recognizing = false;
      };

      this.recognition.onsoundstart = () => {
        this.recognizing = true;
      };

      this.recognition.onsoundend = () => {
        this.recognizing = false;
        this.recognition.stop()
      };

      this.recognition.onresult = async (event) => {

        let interim_transcript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.final_transcript += event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }

        this.final_transcript = this.capitalize(this.final_transcript);
        if (this.final_transcript) {

          console.log("this.final_transcript", this.final_transcript);


          let adresse = this.final_transcript.match(/(J'habite|je suis|adresse|localisation|le quai|le pont|devant|derrière|je suis|au niveau|je me situe|aux? boulevard|aux? carrefour|dans la rue|dans l'avenue|dans le cours)/igm)
          let tel = this.final_transcript.match(/(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/igm)
          let telNew = this.final_transcript.match(/(téléphone|portable)/igm)
          let ville = this.final_transcript.match(/(La ville est|La ville de|La ville et|ville est|ville et|ville de)/igm)
          let birth = this.final_transcript.match(/(née? le|naissance|anniversaire)/igm)

          let fin = this.final_transcript.match(/(fin|finis|terminer|terminé)/igm)

          // if(!this.isReady || description) {
          //   this.description = this.final_transcript
          // }
          // if (!this.isReady) {
          //   this.description = this.final_transcript
          // }


           if (fin) {
            this.audioSrc = require('@/assets/son/voice3.mp3')
            const audioPlayer = this.$refs.audioPlayer;
            audioPlayer.load();
            audioPlayer.play();
            // this.$router.push('/success')
          }

          if (ville && ville.length >= 1) {
            let match = this.final_transcript.split(ville)
              console.log(" match", match);

            this.ville = match[1]


              let ad = encodeURI(this.ville)
              console.log("ad", ad);

              let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${ad}&sensor=true&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
              if (dat.data.results && dat.data.results.length) {
                if (dat.data.results[0]) {
                  let result = dat.data.results[0]

                  for (const component of result.address_components) {

                    console.log("component", component);

                    if (component.types.includes('postal_code')) {
                      this.cp = component.long_name;
                    }

                    if (component.types.includes('postal_code_prefix')) {
                      this.cp = component.long_name;
                    }
                    if (component.types.includes('postal_code_suffix')) {
                      this.cp = component.long_name;
                    }
                  }
                }
              }
          }


          if (adresse) {
            adresse = this.final_transcript
            let reg = /[0-9]{0,3},? (rue|ruelle|boulevard|cours|montée|allé|allée|résidence|passage|avenue|quai|pont|passerelle|chemin|place|impasse)[[a-zA-ZÀ-ÿ0-9-.\' ]{2,}/gmi

            let detect = (str) => {
              let ok = str.match(reg)
              if (ok && ok.length) {
                let res = ok[0]
                let oreg = / (et|ou|puis|voilà|mon) /
                res = res.split(oreg);
                if (res.length) {
                  return res[0]
                }
                return false;
              }

            }

            let res = detect(adresse)
            if (res) {
              this.adresse = res.trim()

              console.log(" this.adresse",  this.adresse);

              let ad = encodeURI(this.adresse)
              console.log("ad", ad);

              let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${ad}&sensor=true&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
              if (dat.data.results && dat.data.results.length) {
                if (dat.data.results[0]) {
                let result = dat.data.results[0]

                 for (const component of result.address_components) {

                  console.log("component", component);
                  if (component.types.includes('locality')) {
                    this.ville = component.long_name; // Renvoie le nom de la ville
                  }

                  if (component.types.includes('postal_code')) {
                    this.cp =  component.long_name;
                  }

                  if (component.types.includes('postal_code_prefix')) {
                   this.cp = component.long_name;
                  }
                  if (component.types.includes('postal_code_suffix')) {
                   this.cp = component.long_name;
                  }
                }
              }
            }
          }

          }

          
          if (birth && birth.length) {

            //this.date = '2022-03-16';
            let reg = /[0-9]{2} (janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre) [0-9]{2,4}/igm

            let match = this.final_transcript.match(reg)
            // console.log("match", match);
            if(match && match.length){
              moment.locale('fr');
               let mom = moment(match[0], "DD MMMM YYYY")
               if (mom.isValid()) {
                this.date = mom.format("DD/MM/YYYY")
               }
            }
           

          }

          if ((tel || telNew) && !this.tel.length) {

            if (tel.length) {
              this.tel = tel[0]
            }
          }

        }
      };

      this.recognition.onend = (event) => {
        this.recognizing = false
        this.recognition.stop()
      }
    },
    previsu(event) {
      const [file] = imgInp.files
      if (file) {
        this.src = URL.createObjectURL(file)
      }
    },
    async geoloc() {
      await this.getLocation()
    },
    async upLong(val) {
      let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${val.lat},${val.lng}&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
      if (dat.data.results && dat.data.status == 'OK' && dat.data.results.length) {
        this.adresse = dat.data.results[0].formatted_address
        // this.cp = dat.data.results[0].formatted_address.match(/[0-9]{5}/)[0]
      }

    },
    async adMaj() {
      let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.adresse}&sensor=false&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
      if (dat.data.results && dat.data.results.length) {
        if (dat.data.results[0]) {
          let one = dat.data.results[0]
          this.center = one.geometry.location
          this.markerLatLng = one.geometry.location
          this.adresse = one.formatted_address
          this.zoom = 17
        }
      }
    },

    getMobileOperatingSystem() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
    async finish() {
        let res = await xhr.post('/sms', {
          sms: this.tel
        })

        console.log("res", res);
      // if (res.data && res.data["MNTDeclarationMobileApp.oResult"]) {
      //   this.$router.push('/success')
      // }
         this.$router.push('/sms')

      // let res = await xhr.post(`/PortailJSON?flowType=EAII&flowName=SRV_Mobile_Data&actionJSON=launch&enforceAsString=false`, datas)
      // if (res.data && res.data["MNTDeclarationMobileApp.oResult"]) {
      //   this.$router.push('/success')
      // }
    },
    stop() {
      this.isReady = true
      this.progress = 0
      this.bufferValue = 0
      clearInterval(this.interval)
      clearTimeout(this.timeout)
      this.recognition.stop()
      this.recognition.onend = (e) => {
        this.recognizing = false
      }
      this.final_transcript = ""
    },
    capitalize(s) {
      var first_char = /\S/;
      return s.replace(first_char, function (m) { return m.toUpperCase(); });
    },
    linebreak(s) {
      var two_line = /\n\n/g;
      var one_line = /\n/g;
      return s.replace(two_line, '<p></p>').replace(one_line, '<br>');
    },
    async speech() {
      this.recognition.start()
      this.progress = 0
      this.bufferValue = 0
      clearInterval(this.interval)
      clearTimeout(this.timeout)

      this.interval = setInterval(() => {
        this.progress += 5
        this.bufferValue += 5
      }, 1000)

      this.recognition.onend = (e) => {
        this.recognizing = false
        clearInterval(this.interval)
        clearTimeout(this.timeout)
        this.progress = 0
        this.bufferValue = 0
        this.final_transcript = ""
      }

      this.timeout = setTimeout(() => {
        this.recognition.stop()
        clearInterval(this.interval)
        clearTimeout(this.timeout)
        this.progress = 0
        this.bufferValue = 0
        this.final_transcript = ""
        this.snackbar = true
        this.snack = "Fin !"
      }, 20000)

    },
    getLocation() {
      if (navigator.permissions && navigator.permissions.query) {

        //try permissions APIs first
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {

          // Will return ['granted', 'prompt', 'denied']
          const permission = result.state;
          // alert(permission)
          //  alert(permission) // prompt
          if (permission === 'granted' || permission === 'prompt') {
            // alert("c'est partis")
            navigator.geolocation.getCurrentPosition(this.showPosition, (message) => {
              alert("Accès non autorisé en localisation. Vérifier vos paramètres d'authorisation sur votre Smartphone.")
            },
              {
                enableHighAccuracy: false,
                timeout: 20000,
                maximumAge: 1000
              }
            );
          }
        });
      }
      else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },

    async showPosition(position) {
      // alert("Show position go");
      // alert(position)
      this.markerLatLng = { lat: position.coords.latitude, lng: position.coords.longitude }
      // alert("gooooiiiinnnng !! ")
      // alert(position)

      // alert(position.coords, "position.coords")
      // alert(position.coords.latitude, "position.latitude")
      // alert(position.coords.longitude, "position.longitude")

      let dat = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&language=fr`)
      //  alert(dat.data.status, "dat.data.status")

      if (dat.data.results && dat.data.status == 'OK' && dat.data.results.length) {

        let addressComponents = dat.data.results[0].address_components;

        // Recherche de la composante "locality" dans les résultats
        for (let component of addressComponents) {
          if (component.types.includes('locality')) {
            this.ville = component.long_name;
            break;
          }
        }

        this.adresse = dat.data.results[0].formatted_address;
        this.cp = dat.data.results[0].formatted_address.match(/[0-9]{5}/)[0];

        // this.zoom = 16
        // this.center =  this.markerLatLng
      }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    selected(id) {
      this.id = id;
    },
    playe(url) {
      this.playing = new Audio(url);
      this.playing.play();
      this.playingpause = true;
    },
    pauses() {
      this.playing.pause();
      this.playingpause = false;
    },
    async edit(destination) {
      this.destination = destination;
      this.dialogNew = true;
    },
    async remove(id) {
      let destinations = await xhr.post("/destinations/remove", { id });
      this.destinations = destinations.data;
    },
    async refresh(destinations) {
      this.destinations = destinations;
    },
    async rand() {
      this.user.token = this.random();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day}/${month}/${year}`;
    },
    random() {
      return Math.random().toString(36).slice(2);
    },
    async filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    async loading() {
      let tab = await xhr.get("/destinations");
      this.destinations = Object.values(tab.data);
    },
    async enable(uid) {
      this.snack = "Ce compte a bien été activé";
      await xhr.post("/user/enable", { uid });
      this.snackbar = true;
      await this.loading();
    },
    async disable(uid) {
      this.snack = "Ce compte a bien été desactivé";
      await xhr.post("/user/disable", { uid });
      this.snackbar = true;
      await this.loading();
    },
    formatDate(datIn) {
      return moment(datIn).format("DD/MM/YYYY");
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
      ...mapState(['email']),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    destinationsFilter() {
      if (this.search.length > 2) {
        let reg = new RegExp(this.search, "ig");
        return this.destinations.filter(
          (elt) =>
            reg.test(elt.titre) ||
            reg.test(elt.mois.map((e) => e.name).join(" ")) ||
            reg.test(elt.short) ||
            reg.test(elt.description) ||
            reg.test(elt.legende) ||
            reg.test(elt.nature) ||
            reg.test(elt.country) ||
            reg.test(elt.type)
        );
      }
      return this.destinations;
    },
    popupCoordinates() {
      // Here we can do some work for calculate proper coordinates
      //...
      return [4.8342778, 45.763421];
    },
  },
};
</script>
