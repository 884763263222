<template>
    <v-card class="pa-2" >
         <v-card-title>
            <v-icon>mdi-airplane</v-icon>
            <p> {{ destination._id ? 'Editer' : 'Ajouter' }} une destination</p>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

         <v-card-text>
            <v-form>
            <v-row>
                <v-col cols="12">
                    <v-text-field v-model="destination.titre"   prepend-icon="mdi-marker"   hint="Ecrivez un titre accrocheur" placeholder="Guide de voyage à Malte" solo label="Titre de la destination"></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                    v-model="destination.mois"
                    prepend-icon="mdi-calendar"
                    item-text="name"
                    return-object  
                    multiple
                    solo
                    label="Mois"
                    :items="mois"></v-select>
                </v-col>

                 <v-col>
                    <v-autocomplete
                        solo
                        label="Pays"
                        v-model="destination.country"
                        :items="countries"
                        prepend-icon="mdi-flag" 
                         item-text="name"
                         item-value="id"
                        ></v-autocomplete>
                </v-col>
                 <v-col>
                    <v-select 
                    :items="['Type A', 'Type B', 'Type C', 'Type D']"
                    v-model="destination.type" placeholder="Guide de voyage à Malte" hint="Type 1"  solo label="Type de la destination" />
                </v-col>
               
                 <v-col>
                   <v-select 
                    :items="['Europe', 'Monde', 'France']"
                   v-model="destination.nature" :hint="destination.nature"  solo label="Nature de la destination" />
                </v-col>
                <v-col>
                    <v-text-field  v-model="destination.copyright" placeholder="Ex: Photo prise en 2020 par Gaël" hint="Auteur de la photo" solo type="url" label="Copyright de la photo"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="4">
                     <v-textarea placeholder="Des eaux cristallines, des citadelles médiévales et des temples mégalithiques, tels sont les trois trésors de l'île de Malte, située au centre de la mer Méditerranée. " v-model="destination.short" prepend-icon="mdi-comment-quote" clearable counter hint="Mettez une courte description ..." solo label="Résumé de la destination"></v-textarea>
                </v-col>
                    <v-col cols="8">
                         <v-textarea placeholder="Un long week-end à Malte vous permet de découvrir La Valette, classée au patrimoine de l’humanité et de visiter notamment la co-cathédrale Saint-Jean et les Trois cités, qui lui font face, de l’autre côté du Grand Harbour. Un tour de l’île vous conduira à la cité médiévale de Mdina puis sur la côte sauvage du sud pour voir les falaises de Dingli. Après la découverte du village de pêcheurs de Marsaxlokk, le matin du dernier jour, vous profiterez de la très belle plage de Golden bay, sur la côte ouest. Si vous consacrez ces trois jours à Gozo, vous visiterez Victoria et sa citadelle et découvrirez Dwejra Point, les restaurants de Malsaforn et le charme de Ramlay bay. En une semaine, vos visites incluront aussi les temples de Tarxien et l’hypogée Hal-Saflieni et les charmants villages du centre comme Naxxar et Siggiewi. Des séjours thématiques vous mèneront sur la route de la Malte des chevaliers ou vous feront vivre au rythme des festas." v-model="destination.description" prepend-icon="mdi-comment-processing" clearable counter hint="Mettez une jolie description :)" solo label="Description de la destination"></v-textarea>
                    </v-col>
                </v-row>

            <v-row>
                 <v-col>
                    <v-text-field v-model="destination.urlMail"  prepend-icon="mdi-email" solo placeholder="Ex: https://www.petitfute.com/p36-malte" type="url" label="Url de la page web"></v-text-field>
                     <v-chip color="secondary" dark class="ma-2"  target="_blank" :href="destination.urlMail" v-if="destination.urlMail && destination.urlMail.length" title="URl Card">
                       <v-icon>mdi-link</v-icon>  {{  destination.urlMail  }}
                     </v-chip>
                </v-col>
                <v-col>
                    <v-text-field hint="URL pointant vers le podcast" v-model="destination.podcast" prepend-icon="mdi-podcast" solo placeholder="Ex: https://podcasts.sudradio.fr/sudaudio/audiodirect/ARCHIVES/les-escapades-petit-fute/240316-escapades.mp3" type="url" label="Url Podcast"></v-text-field>
                    
                    <audio v-if="destination.podcast && destination.podcast.length" title="Podcast" preload="auto" controls loop>
                        <source :src="destination.podcast" type="audio/mp3">
                        <p>Votre navigateur est trop ancien pour lire ce podcast</p>
                    </audio>
                        
                </v-col>
                </v-row>
                
                    <v-card class="pa-2" color="primary" dark>
                        <v-card-title><v-icon class="pa-1">mdi-image-multiple</v-icon>Les photos</v-card-title>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="destination.urlPortrait"  prepend-icon="mdi-link-plus" dark placeholder="https://..." type="url" label="Url Portrait"></v-text-field>
                                <a :href="destination.urlPortrait" target="_blank" v-if="destination.urlPortrait && destination.urlPortrait.length" title="URl Card">
                                    <v-img :width="120" :height="200" :src="destination.urlPortrait" alt="" />
                                </a>
                                <v-text-field  v-model="destination.legendePortrait" prepend-icon="mdi-image-edit"  placeholder="Ex: Marsaxlokk, village de pêche maltais" type="url" label="Légende de photo portrait"></v-text-field>
                                <v-text-field  v-model="destination.copyrightPortrait" prepend-icon="mdi-scale-balance"  placeholder="Ex: Marsaxlokk, village de pêche maltais" type="url" label="Copyrights de photo portrait"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="destination.urlPaysage"  prepend-icon="mdi-link-plus" placeholder="https://..." type="url" label="Url Paysage"></v-text-field>
                                <a :href="destination.urlPaysage" target="_blank" v-if="destination.urlPaysage && destination.urlPaysage.length" title="URl Card">
                                    <v-img :width="280" :height="120" :src="destination.urlPaysage" alt="" />
                                </a>
                                <v-text-field  v-model="destination.legendePaysage" prepend-icon="mdi-image-edit"  placeholder="Ex: Marsaxlokk, village de pêche maltais" type="url" label="Légende de photo paysage"></v-text-field>
                                <v-text-field  v-model="destination.copyrightPaysage" prepend-icon="mdi-scale-balance"  placeholder="Ex: Marsaxlokk, village de pêche maltais" type="url" label="Copyrights de photo paysage"></v-text-field>

                            </v-col>
                            <v-col>
                                <v-text-field v-model="destination.urlCarre"   prepend-icon="mdi-link-plus" placeholder="https://..."  type="url" label="Url photo Carré"></v-text-field>
                                <a :href="destination.urlCarre" target="_blank" v-if="destination.urlCarre && destination.urlCarre.length" title="URl Card">
                                        <v-img :width="200" :height="200" :src="destination.urlCarre" alt="" />
                                </a>
                                <v-text-field   v-model="destination.legendeCarre" prepend-icon="mdi-image-edit" a placeholder="Ex: Marsaxlokk, village de pêche maltais" type="url" label="Légende de photo carré"></v-text-field>
                                <v-text-field  v-model="destination.copyrightCarre" prepend-icon="mdi-scale-balance"  placeholder="Ex: Marsaxlokk, village de pêche maltais" type="url" label="Copyrights de photo carré"></v-text-field>
                            </v-col>
                        </v-row>
            </v-card>
    
        </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
             <v-btn @click="save" :color="destination._id ? 'warning' : 'success'" rounded class="pa-3" :disabled="!destination.titre.length"  x-large size="x-large"><v-icon>mdi-content-save-check</v-icon>{{  destination._id ? 'Editer' : 'Ajouter' }} une destination</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import countries from './countries.js'
    import xhr from '../plugins/axios';

    export default {
    name: "Destination",
    components: {
    },
    props: ['destinationSelected'],
    data(vm) {
        return {
            mois: [],
            destination: {
                titre: "",
                description: "",
                mois: "",
                nature: "",
                country: "",
                podcast: "",
                urlMail: "",
                urlPortrait: "",
                urlPaysage: "",
                urlCarre: "",
                copyrightPaysage: "",
                copyrightPortrait: "",
                copyrightCarre: "",
                imageLegende: "",
                copyright: "",
            },
            countries: []
        }
    },
    methods: {
        async save(){
            let destinations = []
             if (this.destinationSelected._id) {
                 destinations = await xhr.post('/destinations/update', { id: this.destination._id,  destination: this.destination });
             }else{
                 destinations = await xhr.post('/destinations/save', { ...this.destination });
             }
            this.$emit('refresh', destinations.data)
            this.$emit('close')
        },
       
    },
    async created() {
        let mois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre',
            'Octobre', 'Novembre', 'Décembre']
        this.mois = mois.map((moi, index) => { return { name: moi, id: index }})
        this.countries =  Object.keys(countries).map((key) => { return { id: key, name: countries[key]} });
        if(this.destinationSelected.titre){
            console.log(this.destinationSelected);
            this.destination = {...this.destinationSelected}
        }
    },
    watch:{
        destinationSelected(val){
            if(val.titre){
                this.destination = val
            }else{
                this.destination = {
                    titre: "",
                    description: "",
                    mois: "",
                    nature: "",
                    country: "",
                    podcast: "",
                    urlMail: "",
                    urlPortrait: "",
                    urlPaysage: "",
                    urlCarre: "",
                    imageLegende: "",
                    copyright: "",
                }
            }
        }
    }
}


</script>